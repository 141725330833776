#auth {
    min-height: 100vh;
    background-image: url('../assets/img/kids-bg-cool-up.svg'), url('../assets/img/kids-bg-cool-down.svg');
    background-repeat: no-repeat;
    background-position: top center, bottom center;
    background-size: 100% auto, 100% auto;
    background-color: #fffcf5;
    display: flex;
    width: 100%;
    flex-direction: column;
}

#auth-header {
    height: 50px;
    padding: 0.6rem;
    text-align: center;
    flex: 0 0 auto;
}

#auth-header > a > img {
    height: 100%;
}

#auth-body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}